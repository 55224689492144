import {createAction} from '@reduxjs/toolkit'

const initialState = {
    statistics: [],
    anomalies: [],
    chartData: [],
    tableData: [],
    selectedAnomaly: {},
    isLoading: false
}

export const setAnomalies = createAction("dashboardReducer/setAnomalies")
export const setStatistics = createAction("dashboardReducer/setStatistics")
export const setChart = createAction("dashboardReducer/setChart")
export const setTable = createAction("dashboardReducer/setTable")
export const setLoading = createAction("dashboardReducer/setLoading")
export const setSelectedAnomaly = createAction("dashboardReducer/setSelectedAnomaly")

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case setAnomalies().type:
            return {
                ...state,
                anomalies: action.payload,
                isLoading: false
            }
        case setStatistics.type:
            return {
                ...state,
                statistics: action.payload,
                isLoading: false
            }
        case setChart.type:
            return {
                ...state,
                chartData: action.payload,
                isLoading: false
            }
        case setTable.type:
            return {
                ...state,
                tableData: action.payload,
                isLoading: false
            }
        case setSelectedAnomaly.type:
            return {
                ...state,
                selectedAnomaly: action.payload
            }
        case setLoading.type:
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state
    }
}

export default dashboardReducer
